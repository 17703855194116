<template>
  <PersonalBillBonuses />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const PersonalBillBonuses = defineAsyncComponent(
  () => import("@/components/Personal/BillBonuses/PersonalBillBonuses.vue")
);
</script>

<style scoped></style>
