<template>
  <CheckPoll />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const CheckPoll = defineAsyncComponent({
  loader: () => {
    window.lab4u__toggleLoader({
      visible: true,
      isDarkened: false,
      noInteraction: false,
      noScroll: false,
    });
    return import("@/components/Polls/Check/CheckPoll.vue");
  },
});
</script>

<style scoped></style>
