<template>
  <Transition
    :appear="props.withAppear"
    name="Lab4uTransition"
    @leave="leaveHandler"
  >
    <slot></slot>
  </Transition>
</template>

<script setup lang="ts">
import { Lab4uTransitionProps } from "@/components/UI/Transitions/types";
import { computed } from "vue";

const props = withDefaults(defineProps<Lab4uTransitionProps>(), {
  withAppear: false,
  instantRemove: false,
});

function leaveHandler(_element: Element, done: () => void) {
  if (props.instantRemove) {
    done();
  }
}

const transition = computed(() =>
  props?.transitionTime
    ? `${props.transitionTime}s all`
    : "var(--ui-lab4u-transition__fast)"
);
</script>

<style scoped>
.Lab4uTransition-enter-active,
.Lab4uTransition-leave-active {
  transition: v-bind(transition);
}

.Lab4uTransition-enter-from,
.Lab4uTransition-leave-to {
  opacity: 0;
  visibility: hidden;
}
</style>
